.song {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 240px;
    min-height: 70vh;
    padding: 1em;
    font-weight: 400;
    text-align: center;
    gap: 0.5em;

    img {
        max-width: 100%;
    }

    &-title {
        font-size: 2em;
        font-weight: 400;
        color: #000;
    }

    &-artist {
        font-size: 1em;
        font-weight: 300;
        color: #333;
    }
}

.library-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    transition: all 350ms ease-in;
    z-index: 1;
}

.library-folded {
    transform: translateX(calc(-100% + 0.8em));
}

.library {
    max-width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fff;
    box-shadow: 9px 2px 1em #333;
    z-index: 1;

    @media (min-width: 520px) {
        max-width: 50vw;
    }
}

.library-thumb {
    position: relative;
    top: calc(50vh - 2.5em);
    left: 0;
    width: 1em;
    height: 5em;
    border-radius: 0 5em 5em 0;
    box-shadow: 9px 2px 0.5em #333;
    background-color: #999;
    cursor: pointer;
    z-index: 10;

    &:hover {
        background-color: orange;
    }
}

.library-title {
    position: sticky;
    top: 0;
    left: 0;
    padding: 0.5em 1em;
    height: 2.2em;
    font-size: 1.3rem;
    font-weight: 400;
    color: #fff;
    background-color: orange;
}

.library-song {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    align-items: center;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgba(216, 216, 216, 0.25);
    cursor: pointer;

    &:hover {
        background-color: orange;
    }

    &-selected {
        background-color: #999;
    }

    &-art {
        display: flex;
        flex-grow: 1;
        width: 30%;
        justify-content: center;

        img {
            max-width: 100%;
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        padding-left: 1em;
        width: 70%;
        font-weight: 400;

        &-title {
            font-size: 1.1rem;
            font-weight: 500;
            color: #000;
        }

        &-artist {
            font-size: 0.8rem;
            font-weight: 400;
            color: #000;
        }
    }
}

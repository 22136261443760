.player {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 240px;
    width: 100%;
    user-select: none;

    &-title {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 1em;
        font-size: 1.5rem;
        font-weight: 500;
        height: 2em;
        color: #fff;
        background-color: #000;
    }
}

.time-controls {
    display: flex;
    width: 80%;

    .time {
        display: flex;
        justify-content: center;
        min-width: 3.5em;
    }

    .track {
        position: relative;
        width: 100%;
        height: 0.75em;
        margin: 1.3em 0;
        border-radius: 1em;
        overflow: hidden;
    }

    .animate-track {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #dde;
        transition: all 75ms ease-in;
    }

    input {
        position: relative;
        z-index: 1;
        width: 100%;
        cursor: pointer;

        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: transparent;

        &[type="range"] {
            outline: transparent;
            &::-webkit-slider-thumb {
                appearance: none;
                -webkit-appearance: none;
                padding: 1em 0;
                height: 0.9em;
                width: 0.9em;
            }
            &::-moz-range-thumb {
                background-color: transparent;
                border: 0;
            }
        }
    }
    p {
        padding: 1em;
    }

    @media (min-width: 480px) {
        width: 80%;
        max-width: 35em;
    }
}

.player-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    width: 50%;
    max-width: 100%;

    svg {
        cursor: pointer;
        margin: 0.5em;

        &:hover {
            color: orange;
        }
    }

    @media (min-width: 640px) {
        width: 75%;
        max-width: 20em;
    }
}
